import React, { useMemo } from 'react';
import Head from 'next/head';
import getConfig from 'next/config';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from '@apollo/client/core';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { withScalars } from 'apollo-link-scalars';
import fetch from 'isomorphic-unfetch';
import { IncomingMessage, ServerResponse } from 'http';
import { ParsedUrlQuery } from 'querystring';
import { AppTreeType } from 'next/dist/next-server/lib/utils';
import { WithTranslation } from 'next-i18next';
import { UserType } from '@app/components/graphql/types';
import introspectionResult from '@app/components/graphql/schema.json';
import { buildClientSchema } from 'graphql';

const {
  publicRuntimeConfig: { GRAPHQL_ENDPOINT, GRAPHQL_ENDPOINT_LOCAL },
} = getConfig();

const schema = buildClientSchema(introspectionResult as any);
// FIXME:
// Decimal: use bignumber.js or something similar
// Date/DateTime: convert them from/to dayjs/moment
const typesMap = {
  Decimal: {
    serialize: (parsed: number | null) => (parsed == null ? null : parsed.toString()),
    parseValue: (raw: string | null): number | null => (raw == null ? null : parseFloat(raw)),
  },
};

/**
 * `Next` context
 */
export interface NextApolloPageContext {
  /**
   * Error object if encountered during rendering
   */
  err?:
    | (Error & {
        statusCode?: number;
      })
    | null;
  /**
   * `HTTP` request object.
   */
  req?: IncomingMessage & WithTranslation;
  /**
   * `HTTP` response object.
   */
  res?: ServerResponse;
  /**
   * Path section of `URL`.
   */
  pathname: string;
  /**
   * Query string section of `URL` parsed as an object.
   */
  query: ParsedUrlQuery;
  /**
   * `String` of the actual path including query.
   */
  asPath?: string;
  /**
   * `Component` the tree of the App to use if needing to render separately
   */
  AppTree: AppTreeType;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  me?: UserType;
  companyID?: string;
}

/**
 * `Page` type, use it as a guide to create `pages`.
 */
export interface NextApolloPage<P = {}, IP = P> {
  (props: P): JSX.Element | null;
  defaultProps?: Partial<P>;
  displayName?: string;
  /**
   * Used for initial page load data population.
   * Data returned from `getInitialProps` is serialized when server rendered.
   * Make sure to return plain `Object` without using `Date`, `Map`, `Set`.
   * @param ctx Context of `page`
   */
  getInitialProps?(ctx: NextApolloPageContext): Promise<IP> | IP;
}

let apolloClient: any = null;

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 * @param {Function|Class} PageComponent
 * @param {Object} [config]
 * @param {Boolean} [config.ssr=true]
 */
export function withApollo(PageComponent: any, { ssr = true } = {}) {
  const WithApollo = ({ apolloClient: ac, apolloState, ...pageProps }: any) => {
    const client = useMemo(() => ac || initApolloClient(apolloState), []);
    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    );
  };

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component';

    if (displayName === 'App') {
      console.warn('This withApollo HOC only works with PageComponents.');
    }

    WithApollo.displayName = `withApollo(${displayName})`;
  }

  if (ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async (ctx: NextApolloPageContext) => {
      const { AppTree } = ctx;

      // Initialize ApolloClient, add it to the ctx object so
      // we can use it in `PageComponent.getInitialProp`.
      const ac = (ctx.apolloClient = initApolloClient({}, ctx.req?.headers.cookie));

      // Run wrapped getInitialProps methods
      let pageProps = {};
      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx);
      }

      // Only on the server:
      if (typeof window === 'undefined') {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return pageProps;
        }

        // Only if ssr is enabled
        if (ssr) {
          try {
            // Run all GraphQL queries
            const { getDataFromTree } = await import('@apollo/react-ssr');
            await getDataFromTree(<AppTree pageProps={{ ...pageProps, ac }} />);
          } catch (error) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            // Handle them in components via the data.error prop:
            // https://www.apollographql.com/docs/react/api/
            // react-apollo.html#graphql-query-data-error
            console.error('Error while running `getDataFromTree`', error);
          }

          // getDataFromTree does not call componentWillUnmount
          // head side effect therefore need to be cleared manually
          Head.rewind();
        }
      }

      // Extract query data from the Apollo store
      const apolloState = ac.cache.extract();

      return {
        ...pageProps,
        apolloState,
      };
    };
  }

  return WithApollo;
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */
export function initApolloClient(initialState?: any, cookie?: any) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(initialState, cookie);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(initialState, cookie);
  }

  return apolloClient;
}

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 */
function createApolloClient(initialState = {}, cookie?: any) {
  const enhancedFetch = (url: string, init: any) => {
    if (process.env.NODE_ENV !== 'production' && init != null) {
      logRequest(init);
    }
    return fetch(url, {
      ...init,
      headers: {
        ...init.headers,
        cookie,
      },
      credentials: 'same-origin',
    }).then((res: any) => res);
  };
  const ssr = typeof window === 'undefined';
  const link = ApolloLink.from([
    withScalars({
      schema,
      typesMap,
      validateEnums: true,
    }),
    (createUploadLink({
      uri: ssr ? GRAPHQL_ENDPOINT_LOCAL : GRAPHQL_ENDPOINT,
      credentials: 'same-origin',
      fetch: enhancedFetch,
    }) as unknown) as ApolloLink,
  ]);
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    // Disables forceFetch on the server (so queries are only run once)
    ssrMode: ssr,
    cache: new InMemoryCache().restore(initialState),
    link: link as any,
  });
}

const logRequest = (init: RequestInit) => {
  let body;
  if (typeof init.body === 'string') {
    try {
      body = JSON.parse(init.body);
    } catch (e) {
      console.error(e);
    }
  }
  if (typeof window !== 'undefined' && init.body instanceof FormData) {
    const ops = init.body.get('operations');
    if (ops != null && typeof ops === 'string') {
      try {
        body = JSON.parse(ops);
      } catch (e) {
        console.error(e);
      }
    }
  }
  console.log(
    '========================================================================',
    `\nGRAPHQL: Called "${body?.operationName}" with`,
    body?.variables,
    '\n========================================================================'
  );
};
